.customer-content
  background: #F8F8F8

.cardStyles
  display: flex
  align-items: start
  gap: 14px

.onboarding_footer_btn
  width: 100%
  position: fixed
  bottom: 0

.steps-heading
  font-weight: 600
.icons
  font-size: 50px
  color: #525252
.icons-container
  display: flex
  justify-content: center
  gap: 10px
.success-container
  display: flex
  justify-content: center
  flex-direction: column
  align-items: center
  height: 90vh

.onboarding-header
  width: 100%
  height: 64px
  padding: 0 24px
  box-shadow: 0px 0px 4px rgba(9, 30, 66, 0.23)
  display: flex
  align-items: center
  justify-content: start
  position: relative
  top: 0
  box-sizing: border-box
  .logo
    height: 50px
    weight: 100px
  .stepper_style
    margin-left: 25%
    display: flex
    align-items: center
    .appType_heading
      font-size: 20px
      font-weight: 500

.ant-steps-item-icon
  height: 6px
  width: 6px
.icons-name
  font-weight: 500
  margin: 10px 0px

.files
  display: flex
  justify-content: space-between
  align-items: center
  padding: 8px
  height: 20px
  width: 270px
  border: 2px solid #DFE1E6
  cursor: pointer
.filelist
  display: flex
  gap: 10px
  flex-wrap: wrap

.ant-steps-item-title
  font-size: 14px !important
  color: #000 !important

.color-palatte-container
  cursor: pointer
  margin: 3px
  width: 40px
  height: 40px
  border-radius: 50%
  display: flex
  justify-content: center
  align-items: center

.customer_data
  width:100%
  height:100%

.customer_data .NoData
  width:100%
  height:100%
  display: flex
  justify-content: center
  align-items: center
