.no-data
  text-align: center
  margin-top: 150px

  img
    margin-bottom: 26px
    width: 108px
    filter: invert(3%) sepia(2%) saturate(506%) hue-rotate(183deg) brightness(97%) contrast(85%)

  h5
    margin-bottom: 24px
