.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.jobs{
  margin: 24px;
}

.jobs .no-data{
  margin-top: 50px;
}
.jobs .job-item {
  align-items: center;
  background: #fff;
  border: 1px solid #f1f3f8;
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,.04);
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 16px 20px;
}
.jobs .job-item .left, .jobs .job-item .right {
  align-items: center;
  display: flex;
  flex-shrink: 0;
}
.jobs .job-item .avatar {
  align-items: center;
  border-radius: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.jobs .job-item .details {
  margin-left: 17px;
  text-align: left;
}
.jobs .job-item .details .title {
  color: #091e42;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 12px;
  margin-right: 10px;
}
.column-header{
      align-items: center;
      display: flex;
      border-bottom: 2px solid #000;
      margin-bottom: 30px;
      padding-bottom: 10px;
      position: relative;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
