@import '../../styles/vars'

.avatar
  display: flex
  align-items: center
  justify-content: center
  border-radius: 100%
  color: $color-neutral-0
  overflow: hidden

  img
    width: 100%
    height: 100%
